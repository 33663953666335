import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from './Button'

class StatusBar extends Component {
    render() {
        const {handleSignIn, handleSignOut, userSession} = this.props;
        return (
            <div className="StatusBar">
                <div className="title">
                    <Link to="/"><b>GitHuman</b></Link>
                </div>
                <div className="menu">
                    <Button>
                        <Link to="/about">About</Link>
                    </Button>
                    <Button>
                        <Link to="/new/repo">New Repository</Link>
                    </Button>
                    {!userSession.isUserSignedIn() ?
                        <Button className="login" onClick={handleSignIn.bind(this)}>Login</Button>
                        : <Button className="logout" onClick={handleSignOut.bind(this)}>Logout</Button>
                    }
                </div>
            </div>
        );
    }
}

export default StatusBar