import React, {Component} from 'react'
import Highlight from 'react-highlight'
import Button from 'react-bootstrap-button-loader';

class NewRepo extends Component {
    render() {
        const {updateRepo, handleNewRepoSubmit, newRepo, error, userSession, inProgress} = this.props;
        return (
            <span className="NewRepo">
                <br/>
                <p><b>Creating new repository</b></p>
                <p>1. Install <a href="https://golang.org/doc/install" target="_blank">go</a>. IPFS works with Go 1.12.0 or later (Tested with 1.12.5). To check what go version you have installed, type go version</p>

                <p>2. Ensure that go related environment variables are setup properly in .bashrc or equivalent</p>
                <Highlight className="sh">{
                    `$ vi ~/.bashrc\n\nAdd the following lines to .bashrc\n\nexport PATH=$PATH:/usr/local/go/bin\nexport GOPATH="$HOME/go"\nPATH="$GOPATH/bin:$PATH"\n\nReload .bashrc\n$ source ~/.bashrc`
                }</Highlight>

                <p>3. Install <a href="https://docs.ipfs.io/guides/guides/install/" target="_blank">go-ipfs</a>. Make sure you run go-ipfs 0.4.17 or newer as we need git support (Tested with 0.4.22). Command to check version: ipfs version</p>

                <p>4. Install <a href="https://github.com/viraja1/git-remote-ipld#installation" target="_blank">git-remote-ipld</a></p>

                <p>5. Setup <a href="https://github.com/ipfs/go-ipfs/blob/master/docs/plugins.md#in-tree" target="_blank">git plugin</a> for go-ipfs</p>
                <Highlight className="sh">{
                    `$ git clone https://github.com/ipfs/go-ipfs.git\n$ cd go-ipfs\n$ make build_plugins\n$ ls plugin/plugins/*.so\n$ mkdir -p ~/.ipfs/plugins/\n$ cp plugin/plugins/git.so ~/.ipfs/plugins/\n$ chmod +x ~/.ipfs/plugins/git.so`
                }</Highlight>

                <p>6. Start ipfs daemon in a separate tab</p>
                <Highlight className="sh">{
                    `$ ipfs daemon`
                }</Highlight>

                <p>7. Initialize new repository</p>
                <Highlight className="sh">{
                    `$ mkdir hello_world\n$ cd hello_world\n$ git init`
                }</Highlight>

                <p>8. Add and commit some files</p>
                <Highlight className="sh">{
                    `$ echo hello world > README.md\n$ git add README.md\n$ git commit -m "Initial commit"`
                }</Highlight>

                <p>9. Push to IPFS!</p>
                <Highlight className="sh">{
                    `$ git push ipld:: master\nNote down the CID shown in output message\ne.g. Pushed to IPFS as ipld://QmZpn3aZgH4XvWrgAJuiUcArd8MXBibp3FRukVmxFkewXw`
                }</Highlight>

                <p>10. View your repo!</p>
                <p>Copy the Head CID and go to #/repo/[head cid] url (see demo tree url e.g. https://githuman.com/#/repo/QmTTo4ZcCbHCuARMtgGG4Y7aSuVmr9sUPdPsUdBB3TSghw)</p>

                {/*TODO: Link creator*/}
                {/*TODO/IPNS: It will be a bit different for ipns repos*/}
                <p>11. Save repo details using blockstack login + gaia storage (login mode required). Once you login, then the submission form will be visible below. You can see all the saved repos on homepage.</p>

                <p><b>Clone an example repository</b></p>
                <Highlight className="sh">{
                    `$ git clone ipld://QmPiVpeWyAgdBArXu9bot8jCvF1Jkvonrknq6Kbm774Ucy`
                }</Highlight>

                {userSession.isUserSignedIn() &&
                <div className="new-repo">
                    <div className="col-md-8" key="editor1">
                        <br/>
                        <p className="h3">Add New Repo</p>
                        <input type="text" className="form-control"
                               value={newRepo.title}
                               onChange={e => updateRepo('title', e.target.value)}
                               placeholder="Title" required={true}
                        />
                        <br/>
                        <input type="text" className="form-control"
                               value={newRepo.description}
                               onChange={e => updateRepo('description', e.target.value)}
                               placeholder="Description" required={true}
                        />
                        <br/>
                        <input type="text" className="form-control"
                               value={newRepo.git_id}
                               onChange={e => updateRepo('git_id', e.target.value)}
                               placeholder="CID" required={true}
                        />
                        <br/>
                    </div>
                    <div className="col-md-12">
                        <Button variant="secondary"
                            onClick={e => handleNewRepoSubmit(e)}
                            loading={inProgress}
                        >
                            Submit
                        </Button>
                        <br/>
                        <br/>
                        {error && <div>Error: {error}</div>}
                        <br/>
                        <br/>
                    </div>

                </div>
                }

            </span>
        );
    }
}

export default NewRepo
