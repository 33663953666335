import React, {Component} from 'react'
import {Card} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';

let featuredRepos = [
    {
        id: 1,
        title: "Awesome Python",
        description: "A curated list of awesome Python frameworks, libraries, software and resources.",
        git_id: "QmTTo4ZcCbHCuARMtgGG4Y7aSuVmr9sUPdPsUdBB3TSghw"
    },
    {
        id: 2,
        title: "Awesome Decentralized Web",
        description: "A curated list about the decentralized technologies and tools.",
        git_id: "QmNbSnmSsQzY8S1gaQoSzGnrQWrjbyefw1yB3EV22cksuV"
    },
    {
        id: 3,
        title: "Blockstack Todo App",
        description: "A simple Vue.js Todo app built for the decentralized web.",
        git_id: "QmPiVpeWyAgdBArXu9bot8jCvF1Jkvonrknq6Kbm774Ucy"
    },
    {
        id: 4,
        title: "Hello World",
        description: "Hello World",
        git_id: "QmZpn3aZgH4XvWrgAJuiUcArd8MXBibp3FRukVmxFkewXw"
    }
];

class Home extends Component {
    render() {
        const {isLoading, repos, userSession, deleteRepo} = this.props;
        return (
            <span className="Home">
                <p>
                    GitHuman is a decentralized alternative to GitHub and GitLab.
                    It's built with <a href="https://ipfs.io">IPFS</a> and <a href="https://ipld.io">IPLD</a>.
                </p>
                <span className="h5" style={{textTransform: 'capitalize'}}>Featured Repos</span>
                 <br/>
                 <br/>
                {featuredRepos.map((repo) => (
                    <Card key={repo.id}>
                        <Card.Body>
                            <Card.Text>
                                <b>Title: </b> {repo.title}
                            </Card.Text>
                            <Card.Text>
                                <b>Description: </b> {repo.description}
                            </Card.Text>
                            <Card.Text>
                                <b>CID: </b> <a href={"#/repo/" + repo.git_id}>{repo.git_id}</a>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    )
                )}
                <br/>
                <br/>
                {userSession.isUserSignedIn() &&
                <div>
                    <div>
                        <span className="h5" style={{textTransform: 'capitalize'}}>Your Repos</span>
                        <br/>
                        <br/>
                        {isLoading && <span>Fetching Repos...</span>}
                        {repos.map((repo) => (
                            <Card key={repo.id}>
                                <Card.Body>
                                    <Card.Text>
                                        <b>Title: </b> {repo.title}
                                    </Card.Text>
                                    <Card.Text>
                                        <b>Description: </b> {repo.description}
                                    </Card.Text>
                                    <Card.Text>
                                        <b>CID: </b> <a href={"#/repo/" + repo.git_id}>{repo.git_id}</a>
                                    </Card.Text>
                                </Card.Body>
                                <FaTrash onClick={e => deleteRepo(e, repo.id)}
                                         style={{cursor: 'pointer', marginLeft: "20px", marginBottom: "20px"}}/>
                            </Card>
                            )
                        )}
                    </div>
                </div>
                }
            </span>
        );
    }
}

export default Home
