import React, {Component} from 'react'
import Carousel from 'react-bootstrap/Carousel';

class About extends Component {
    render() {
        return (
            <div>
                <br/>
                GitHuman is a decentralized alternative to GitHub and GitLab (Git sharing for everyone without any
                discrimination or restriction). It's built with IPFS and IPLD. It works without a centralised server.
                Host your git repositories using IPFS + IPLD. You also have an option to view the repo files using a
                web UI with the CID generated for the GIT repo when it was pushed. You can easily share the repo link
                with anyone. If you are logged in using blockstack, then it allows you to store your repo META details
                along with the CID. This data is encrypted and stored securely using blockstack login + gaia storage.
                <br/>
                <br/>
                <p style={{fontSize: "18px"}}><b>Screenshots Slideshow</b></p>
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_1.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_2.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_3.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_4.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_5.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_6.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_7.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_8.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_9.png"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/screenshots/githuman_10.png"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

export default About
