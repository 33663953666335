import React, {Component} from 'react'
import {Switch, Route} from 'react-router-dom'
import Home from './components/Home'
import Repo from './components/Repo'
import Commit from './components/Commit'
import Commits from './components/Commits'
import Compare from './components/Compare'
import Panic from './components/Panic'
import StatusBar from "./components/StatusBar";
import NewRepo from "./components/NewRepo";
import About from "./components/About";

import {AppConfig, UserSession} from "blockstack";

const appConfig = new AppConfig(['store_write']);
const userSession = new UserSession({appConfig: appConfig});

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: false,
            newRepo: {
                title: '',
                description: '',
                git_id: ''
            },
            repos: [],
            isLoading: false,
            error: '',
            inProgress: false
        }
    }

    updateRepo(key, value) {
        const repo = this.state.newRepo;
        repo[key] = value;
        this.setState({
            newRepo: repo
        })
    }

    deleteRepo(event, id) {
        const updatedRepos = this.state.repos.filter(repo => {
            return repo.id !== id;
        });
        const options = {encrypt: true};
        this.setState({
            repos: updatedRepos
        });
        userSession.putFile('repos.json', JSON.stringify(updatedRepos), options)
        .then(() => {

        })
    }

    handleNewRepoSubmit(event) {
        let newRepo = this.state.newRepo;
        if (typeof newRepo.title === "undefined" || newRepo.title.trim() === "") {
            this.setState({error: "Valid title is required"});
            return;
        }
        if (typeof newRepo.description === "undefined" || newRepo.description.trim() === "") {
            this.setState({error: "Valid description is required"});
            return;
        }
        if (typeof newRepo.git_id === "undefined" || newRepo.git_id.trim() === "") {
            this.setState({error: "Valid git id is required"});
            return;
        }
        this.setState({error: '', inProgress: true});
        this.saveNewRepo(newRepo);
    }

    saveNewRepo(newRepo) {
        const {repos} = this.state;
        newRepo.id = Math.round(new Date().getTime() / 1000);
        newRepo.created_at = Date.now();
        repos.unshift(newRepo);
        const options = {encrypt: true};
        userSession.putFile('repos.json', JSON.stringify(repos), options)
        .then(() => {
            this.setState({
                repos: repos
            });
            this.setState({
                newRepo: {
                    title: '',
                    description: '',
                    git_id: ''
                },
                inProgress: false
            });
            window.location = '/';
        })
        .catch(() => {
            this.setState({inProgress: false})
        });
    }

    fetchData() {
        this.setState({isLoading: true});
        const options = {decrypt: true};
        userSession.getFile('repos.json', options)
        .then((file) => {
            let repos = JSON.parse(file || '[]');
            this.setState({
                repos: repos,
                isLoading: false
            })
        })
        .finally(() => {
            this.setState({isLoading: false})
        })
    }

    handleSignIn(e) {
        e.preventDefault();
        userSession.redirectToSignIn();
    }

    handleSignOut(e) {
        e.preventDefault();
        userSession.signUserOut(window.location.origin);
    }

    render() {
        if (!window.ipfs) {
            return (
                <Panic message="No window.ipfs available, install ipfs-companion and enable it!"/>
            )
        }
        const {error, newRepo, repos, inProgress, isLoading} = this.state;
        return (
            <div className="App sans-serif">
                <header>
                    <StatusBar userSession={userSession} handleSignOut={this.handleSignOut}
                               handleSignIn={this.handleSignIn}/>
                </header>
                <main>
                    <Switch>
                        <Route exact path="/"
                               render={() => <Home userSession={userSession}
                                                   repos={repos}
                                                   isLoading={isLoading}
                                                   deleteRepo={this.deleteRepo.bind(this)}/>}/>
                        <Route exact path="/new/repo" render={() => <NewRepo updateRepo={this.updateRepo.bind(this)}
                                                                             inProgress={inProgress}
                                                                             handleNewRepoSubmit={this.handleNewRepoSubmit.bind(this)}
                                                                             error={error} userSession={userSession}
                                                                             newRepo={newRepo}/>}/>
                        <Route path="/repo/:repoCid/commit/:commitCid" component={Commit}/>
                        <Route path="/repo/:repoCid/commits/:branch" component={Commits}/>
                        <Route path="/repo/:repoCid/compare/:branch...:branch" component={Compare}/>
                        <Route path="/repo/:repoCid" component={Repo}/>
                        <Route path="/about" component={About}/>
                    </Switch>
                </main>
            </div>
        );
    }

    componentWillMount() {
        if (userSession.isSignInPending()) {
            userSession.handlePendingSignIn().then((userData) => {
                //window.location = window.location.origin;
                this.setState({userData: userData})
            });
        }

    }

    componentDidMount() {
        if (userSession.isUserSignedIn()) {
            this.fetchData()
        }
    }
}

export default App;
